/* 验证金额 */
export function validateMoney(val) {
  const reg = /^([1-9][0-9]{0,7})((\.([0-9]{1,2}))?)$|^[0](\.([0-9]{1,2}))$/
  return val ? reg.test(val) && parseFloat(val) <= 21474836.47 : true
}

/* 验证正整数 */
export function validateIntNumber(val) {
  const reg = /^[1-9]\d*$/
  return reg.test(val)
}

/* 验证手机号 */
export function validateMobile(val) {
  const reg = /^1\d{10}$/
  if (val) {
    return reg.test(val)
  } else {
    return true
  }
}

/* 验证固话 */
export function validateTelephone(val) {
  const reg = /^(\(\d{3,4}-\)|\d{3,4}-)?\d{7,8}$/
  return reg.test(val)
}

/**
 * validate email
 * @param email
 * @returns {boolean}
 */
export function validateEmail(email) {
  const reg = /^[A-Za-z\d]+[A-Za-z\d\-_\\.]*@([A-Za-z\d]+[A-Za-z\d\\-]*\.)+[A-Za-z]{2,4}$/
  return email ? reg.test(email) : true
}
