import { axios } from '@/utils/request'

/**
 * type: factory/fixed_fee/wharf/district/location/fleet/driver/vehicle/pay_target
 */
export function getMetaInfoPage(type, parameter) {
  return axios({
    url: `wms/meta/${type}/list`,
    method: 'get',
    params: parameter
  })
}

export function getMetaOptions(type, params) {
  return axios({
    url: `wms/meta/${type}/option`,
    method: 'get',
    params
  })
}

export function saveMetaTypeInfo(type, data) {
  return axios({
    url: `wms/meta/${type}/save`,
    method: 'post',
    data
  })
}

export function createMetaTypeInfo(type, data) {
  return axios({
    url: `wms/meta/${type}/create`,
    method: 'post',
    data
  })
}

export function updateMetaTypeInfo(type, data) {
  return axios({
    url: `wms/meta/${type}/update`,
    method: 'post',
    data
  })
}

// 删除基础信息模块基础数据
export function deleteMetaTypeInfo(type, data) {
  return axios({
    url: `wms/meta/${type}/delete`,
    method: 'post',
    data
  })
}
// 删除基础信息模块基础数据
export function deleteFactoryMetaTypeInfo(type, data) {
  return axios({
    url: `wms/meta/${type}/remove`,
    method: 'post',
    data
  })
}

export function getFactoryContractList(id) {
  return axios({
    url: `wms/meta/factory/contact_list?id=${id}`,
    method: 'get'
  })
}

export function createContractTypeInfo(type, data) {
  return axios({
    url: `wms/meta/${type}/saveOrUpdate`,
    method: 'post',
    data
  })
}

export function getContractInfoPage(type, parameter) {
  return axios({
    url: `wms/meta/${type}/getContractPage`,
    method: 'get',
    params: parameter
  })
}
