<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <!--          <a-col v-bind="GLOBAL.queryColSpan">-->
          <!--            <a-form-item label="客户名称">-->
          <!--              <a-select-->
          <!--                showSearch-->
          <!--                allowClear-->
          <!--                placeholder="请选择客户名称"-->
          <!--                style="width: 100%"-->
          <!--                v-model="queryParam.customer_id"-->
          <!--              >-->
          <!--                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>-->
          <!--              </a-select>-->
          <!--            </a-form-item>-->
          <!--          </a-col>-->
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="合同名称">
              <a-input v-model="queryParam.contract_name"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
      <a-button type="danger" icon="delete" @click="handleDelete('all')">删除</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="handleEdit(record, index)">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete('row', record)">删除</a>
        </template>
      </span>
    </s-table>

    <a-modal :width="640" :visible="formVisible"
             :title="modalFlag === 'create' ? '创建合同' : '编辑合同'"
             :confirmLoading="confirmLoading"
             @ok="handleSubmit" @cancel="formVisible = false">
      <a-form-model layout="vertical" :model="form"
                    :rules="rules" ref="form">
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="客户名称" prop="customer_id">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="合同名称">
              <a-input v-model="form.contract_name" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="日期">
              <a-date-picker v-model="form.contract_date" class="full-width"></a-date-picker>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="描述">
              <a-textarea v-model="form.description" placeholder="" :rows="1" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <edit-table :columns="feeColumns" :sourceData="form.contract_fee" @getTableDate="getFeeData"></edit-table>
        </a-row>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable, EditTable } from '@/components'
import { getContractInfoPage, deleteMetaTypeInfo, createContractTypeInfo } from '@/api/wms/meta'
import { getCommonOptions, getCustomer } from '@/api/common'
import { validateMoney } from '@/utils/validate'
import debounce from 'lodash/debounce'
import moment from 'moment'
export default {
  components: {
    STable,
    EditTable
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      customerInfo: undefined,
      fetching: false,
      queryParam: {},
      customerOps: [],
      feeOps: [],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '客户名称',
          dataIndex: 'customer_name'
        },
        {
          title: '合同名称',
          dataIndex: 'contract_name'
        },
        {
          title: '合同日期',
          dataIndex: 'contract_date',
          customRender: (text) => {
            return moment(text).format('YYYY/MM/DD')
          }
        },
        // {
        //   title: '合同信息',
        //   dataIndex: 'contract_fee'
        // },
        {
          title: '描述',
          dataIndex: 'description'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      loadData: parameter => {
        return getContractInfoPage('WmsContract', Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      },
      form: {
        id: null,
        customer_id: null,
        contract_name: null,
        contract_date: null,
        contract_fee: [],
        description: null
      },
      rules: {
        customer_id: [
          { required: true, message: '请选择客户名称', trigger: 'blur' }
        ]
      },
      formVisible: false,
      confirmLoading: false,
      modalFlag: 'create',
      feeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_id',
          width: 150,
          extra: {
            type: 'select',
            func: this.getCommonOptions,
            params: ['fee', { department: 1, type: 1 }],
            options: []
          }
        },
        {
          title: '箱型',
          dataIndex: 'box_id',
          width: 150,
          extra: {
            type: 'select',
            func: this.getCommonOptions,
            params: ['box_info'],
            options: []
          }
        },
        {
          title: '金额',
          dataIndex: 'money',
          width: 150
        }
      ]
    }
  },
  created() {
  },
  methods: {
    getCommonOptions,
    moment,
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: 1,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.form.customer_id = value.key
      this.customerOps = []
      this.fetching = false
    },
    getFeeData(data) {
      this.form.contract_fee = data
    },
    checkAmount(rule, value, callback) {
      if (validateMoney(value)) {
        callback()
      } else {
        callback(new Error('请输入合法的金额'))
      }
    },
    handleCreate() {
      this.modalFlag = 'create'
      this.formVisible = true
      this.form = {
        id: null,
        customer_id: null,
        contract_name: null,
        contract_date: null,
        contract_fee: [],
        description: null
      }
    },
    handleEdit(record) {
      this.modalFlag = 'update'
      this.formVisible = true
      this.customerInfo = {
        key: record.customer_id,
        label: record.customer_name
      }
      this.form = record
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.confirmLoading = true
          const data = {
            id: this.form.id,
            customer_id: this.form.customer_id,
            contract_name: this.form.contract_name,
            contract_date: moment(this.form.contract_date).valueOf(),
            contract_fee: this.form.contract_fee,
            description: this.form.description
          }
          createContractTypeInfo('WmsContract', data).then(u => {
            this.confirmLoading = false
            this.formVisible = false
            this.$notification['success']({
              message: '提示',
              description: this.modalFlag === 'create' ? '创建成功.' : '编辑成功'
            })
            this.$refs.table.refresh(true)
          }).catch(_ => {
            this.formVisible = false
            this.confirmLoading = false
          })
        }
      })
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteMetaTypeInfo('WmsContract', { ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>
